import React from 'react';
import { createRoot } from 'react-dom/client'
// import ReactDOM from 'react-dom';
import { parse as parseQuery } from 'querystring';

import App from './App';

import Types from './modules/types';

const {
  REACT_APP_SERVER_HOST,
  REACT_APP_SERVER_PROTOCOL,
  REACT_APP_HTTP_PORT,
  REACT_APP_API_PATH,
  REACT_APP_PACKMAN_HOME_URL,
} = process.env;

const SERVER_URL = `${
  REACT_APP_SERVER_PROTOCOL
}://${
  REACT_APP_SERVER_HOST
}${
  REACT_APP_HTTP_PORT === '80' ? '' : `:${REACT_APP_HTTP_PORT}`
}`;

// const API_URL = '/api';
const API_URL = `${SERVER_URL}${REACT_APP_API_PATH}`;
// const API_URL = 'https://thrs-packman-api.tunnelto.dev/api';
// const API_URL = 'https://thrs-.packman.app/api';

// const API_URL = 'https://shop.packman.app/api';
// const API_URL = 'http://localhost:8000/api';

const IS_CUSTOM_DOMAIN = Types.PACKMAN_STORE_HOSTNAMES.includes(
  window.location.hostname
) === false;
// const IS_CUSTOM_DOMAIN = false;

const searchParams = parseQuery(window.location.search.slice(1));

createRoot(window.document.getElementById('root')).render(
  <App
    serverUrl={SERVER_URL}
    apiUrl={API_URL}
    homeUrl={REACT_APP_PACKMAN_HOME_URL}
    isCustomDomain={IS_CUSTOM_DOMAIN}
    iframe={`${searchParams.iframe}` === 'true'}
    token={`${searchParams.token}`}
  />
);
