import QueryString from 'querystring';

import TypesHelpers from './types.helpers';
import TypesInternationalization from './types.internationalization';

const Types = {};

Object.assign(
  Types,
  {
    TABBY_MERCHANT_CODE_SUFFIX_MAP: {
      ae: 'ae',
      sa: 'sa',
      bh: 'bd',
      kw: 'kd',
      qa: 'qr',
    },
  },
  TypesHelpers.createValues('SYSTEM_COUNTRY', [
    ['ae', 'AED', 'Asia/Dubai', {
      signup: true,
      VAT: 0.05,
      checkoutComChannel: 'uae',
      paymentMethods: ['CARD', 'COD_CASH', 'COD_CARD', 'TAMARA', 'FREE'],
      paymentCardProvider: 'CHECKOUT',
      // eslint-disable-next-line max-len
      paymentProviders: ['PAYTABS', 'CHECKOUT', 'TABBY', 'STRIPE', 'PAYMOB', 'PAYMOB_PIXEL'],
      // eslint-disable-next-line max-len
      supportedPaymentCardProviders: ['PAYTABS', 'CHECKOUT', 'STRIPE', 'PAYMOB', 'PAYMOB_PIXEL'],
      tabbySupportedCountries: ['ae', 'sa', 'bh', 'kw', 'qa'],
      defaultServiceFee: {
        supported: true,
        chargedTo: 'CUSTOMER',
        fixed: 3.95,
        percentage: 0,
      },
      warehouse: {
        details: {
          name: 'Packman Storage',
          email: 'hello@packman.app',
          phoneNumber: {
            code: '971',
            country: 'ae',
            number: '502267337',
          },
          address: {
            line1: 'Business Bay',
            line2: 'Burlington Tower, 1004',
            country: 'ae',
            coordinates: [
              55.2648683,
              25.18523,
            ],
            postalCode: '00000',
            city: 'Dubai',
          },
        },
        // 9 - 18 except fri/sat
        hours: new Array(7).join('.').split('.').map(
          (_, i) => (i > 4 ? [-1] : [9, 18])
        ),
      },
      subscriptions: {
        ...TypesHelpers.createValues(
          'SUBSCRIPTION',
          [
            {
              id: 'TIER_10',
              label: '10% - 1000 AED / 6m',
              plan: 'Starter',
              duration: '6_MONTHS',
              monthly: 1000,
              price: 1000,
              percentage: 10,
              legacy: true,
            },
            {
              id: 'TIER_20',
              label: '5% - 2500 AED / 6m',
              plan: 'Pro',
              duration: '6_MONTHS',
              monthly: 2500,
              price: 2500,
              percentage: 5,
              legacy: true,
            },
            {
              id: 'TIER_30',
              label: '10% - 1800 AED / 12m',
              plan: 'Starter',
              duration: '1_YEAR',
              monthly: 1800,
              price: 1800,
              percentage: 10,
              legacy: true,
            },
            {
              id: 'TIER_40',
              label: '5% - 4500 AED / 12m',
              plan: 'Pro',
              duration: '1_YEAR',
              monthly: 4500,
              price: 4500,
              percentage: 5,
              legacy: true,
            },
            {
              id: 'TIER_45',
              label: '7999 AED + 0%',
              plan: 'Expert+',
              duration: '1_YEAR',
              monthly: 7999,
              price: 7999,
              percentage: 0,
              legacy: true,
            },
            {
              id: 'TIER_50',
              label: '2500 AED + 5%',
              plan: 'Expert',
              duration: '1_YEAR',
              monthly: 2500,
              price: 2500,
              percentage: 5,
              legacy: true,
            },
            {
              id: 'TIER_60',
              label: '1000 AED + 10%',
              plan: 'Beginner',
              duration: '1_YEAR',
              monthly: 1000,
              price: 1000,
              percentage: 10,
              legacy: true,
            },
            {
              id: 'TIER_70',
              label: 'AED 12,800.00 + VAT per year / 0%',
              plan: 'Expert+',
              duration: '1_YEAR',
              annually: 12800,
              price: 12800,
              percentage: 0,
              legacy: true,
            },
            {
              id: 'TIER_80',
              label: 'AED 3,600.00 + VAT per year / 5%',
              plan: 'Expert',
              duration: '1_YEAR',
              annually: 3600,
              price: 3600,
              percentage: 5,
              legacy: true,
            },
            // ACTIVE (legacy)
            ...[
              [
                '1_YEAR',
                '1-year',
                'per year',
                'Yearly',
                [1000, 3600, 12800],
              ],
              [
                '3_MONTHS',
                '3-month',
                'quarterly',
                'Quarterly',
                [300, 1000, 3840],
              ],
              [
                '1_MONTH',
                '1-month',
                'per month',
                'Monthly',
                [150, 400, 1400],
              ],
            ].reduce(
              (
                agr, [
                  duration,
                  durationName,
                  durationLabel,
                  durationPlanLabel,
                  pricePerPlan,
                ], durationIndex,
              ) => {
                [
                  ['Beginner', 10, 'beginner'],
                  ['Expert', 5, 'expert'],
                  ['Expert+', 0, 'expert-plus'],
                ].forEach(([label, percentage, name], planIndex) => {
                  const price = pricePerPlan[planIndex];
                  agr.push({
                    id: `TIER_${
                      100
                      + (durationIndex * 10)
                      + (planIndex) * 1
                    }`,
                    label: `${
                      label
                    } ${
                      durationPlanLabel
                    } / AED ${
                      TypesHelpers.decimalize(price)
                    } + VAT ${
                      durationLabel
                    } / ${
                      percentage
                    }%`,
                    plan: label,
                    duration,
                    durationLabel,
                    price,
                    percentage,
                    name: `${name}_${durationName}`,
                    legacy: true,
                  });
                });
                return agr;
              },
              [],
            ),
            // ACTIVE
            ...[
              ['1_YEAR', '1-year', 'per year', 'Yearly', [3600]],
              ['3_MONTHS', '3-month', 'quarterly', 'Quarterly', [950]],
              ['1_MONTH', '1-month', 'per month', 'Monthly', [340]],
            ].reduce(
              (
                agr, [
                  duration,
                  durationName,
                  durationLabel,
                  durationPlanLabel,
                  pricePerPlan,
                ], durationIndex,
              ) => {
                [
                  ['Standard', 0, 'standard'],
                ].forEach(([label, percentage, name], planIndex) => {
                  const price = pricePerPlan[planIndex];
                  agr.push({
                    id: `TIER_${
                      200
                      + (durationIndex * 10)
                      + (planIndex) * 1
                    }`,
                    label: `${
                      label
                    } ${
                      durationPlanLabel
                    } / AED ${
                      TypesHelpers.decimalize(price)
                    } + VAT ${
                      durationLabel
                    } / ${
                      percentage
                    }%`,
                    plan: label,
                    duration,
                    durationLabel,
                    price,
                    percentage,
                    name: `${name}_${durationName}`,
                  });
                });
                return agr;
              },
              [],
            ),
          ],
        ),
      },
      subscriptionsReseller: {
        ...TypesHelpers.createValues('SUBSCRIPTION', [{
          id: 'TIER_10',
          label: '500 AED year + 10%',
          plan: 'Reseller',
          duration: '1_YEAR',
          monthly: 500,
          price: 500,
          percentage: 10,
        }]),
      },
    }],
    ['om', 'OMR', 'Asia/Dubai', {
      signup: true,
      VAT: 0.05,
      checkoutComChannel: 'intl',
      paymentMethods: ['CARD', 'COD_CASH', 'COD_CARD', 'FREE'],
      paymentCardProvider: 'CHECKOUT',
      supportedPaymentCardProviders: ['CHECKOUT', 'PAYTABS'],
      paymentProviders: ['CHECKOUT', 'PAYTABS'],
      warehouse: {
        details: {
          name: 'Packman Storage',
          email: 'hello@packman.app',
          phoneNumber: {
            code: '968',
            country: 'om',
            number: '22478888',
          },
          address: {
            line1: 'Al Maha Street',
            line2: 'Boushar, Muscat, Oman',
            country: 'om',
            coordinates: [
              58.4130798,
              23.5711523,
            ],
            postalCode: '112',
            city: 'Muscat',
          },
        },
        // 9 - 18 except fri/sat
        hours: new Array(7).join('.').split('.').map(
          (_, i) => (i > 4 ? [-1] : [9, 17])
        ),
      },
      subscriptions: {
        ...TypesHelpers.createValues(
          'SUBSCRIPTION',
          [
            {
              id: 'TIER_50',
              label: '250 OMR + 5%',
              plan: 'Expert',
              name: 'expert_1-year',
              duration: '1_YEAR',
              monthly: 250,
              price: 250,
              percentage: 5,
              legacy: true,
            }, {
              id: 'TIER_60',
              label: '100 OMR + 10%',
              plan: 'Beginner',
              name: 'beginner_1-year',
              duration: '1_YEAR',
              monthly: 100,
              price: 100,
              percentage: 10,
              legacy: true,
            },
            // ACTIVE (legacy)
            {
              id: 'TIER_70',
              label: 'OMR 1,280.00 + VAT per year / 0%',
              plan: 'Expert+',
              name: 'expert-plus_1-year',
              duration: '1_YEAR',
              annually: 1280,
              price: 1280,
              percentage: 0,
              legacy: true,
            }, {
              id: 'TIER_80',
              label: 'OMR 360.00 + VAT per year / 5%',
              plan: 'Expert',
              name: 'expert_1-year',
              duration: '1_YEAR',
              annually: 360,
              price: 360,
              percentage: 5,
              legacy: true,
            },
            // ACTIVE
            ...[
              ['1_YEAR', '1-year', 'per year', 'Yearly', [360]],
              ['3_MONTHS', '3-month', 'quarterly', 'Quarterly', [95]],
              ['1_MONTH', '1-month', 'per month', 'Monthly', [34]],
            ].reduce(
              (
                agr, [
                  duration,
                  durationName,
                  durationLabel,
                  durationPlanLabel,
                  pricePerPlan,
                ], durationIndex,
              ) => {
                [
                  ['Standard', 0, 'standard'],
                ].forEach(([label, percentage, name], planIndex) => {
                  const price = pricePerPlan[planIndex];
                  agr.push({
                    id: `TIER_${
                      200
                      + (durationIndex * 10)
                      + (planIndex) * 1
                    }`,
                    label: `${
                      label
                    } ${
                      durationPlanLabel
                    } / OMR ${
                      TypesHelpers.decimalize(price)
                    } + VAT ${
                      durationLabel
                    } / ${
                      percentage
                    }%`,
                    plan: label,
                    duration,
                    durationLabel,
                    price,
                    percentage,
                    name: `${name}_${durationName}`,
                  });
                });
                return agr;
              },
              [],
            ),
          ],
        ),
      },
      subscriptionsReseller: {
        ...TypesHelpers.createValues('SUBSCRIPTION', [{
          id: 'TIER_10',
          label: '5 OMR year + 10%',
          plan: 'Reseller',
          duration: '1_YEAR',
          monthly: 5,
          price: 5,
          percentage: 10,
        }]),
      },
    }],
    ['sa', 'SAR', 'Asia/Riyadh', {
      signup: true,
      VAT: 0.15,
      checkoutComChannel: 'intl',
      paymentMethods: ['CARD', 'COD_CASH', 'COD_CARD', 'FREE'],
      paymentCardProvider: 'PAYMOB',
      supportedPaymentCardProviders: ['PAYMOB'],
      paymentProviders: ['PAYMOB'],
      warehouse: {
        details: {
          name: 'Packman Storage',
          email: 'hello@packman.app',
          phoneNumber: {
            code: '966',
            country: 'sa',
            number: '22478888',
          },
          address: {
            line1: 'Makkah Al Mukarramah Br Rd',
            country: 'sa',
            coordinates: [
              46.7215489,
              24.6929615,
            ],
            postalCode: '12623',
            city: 'Riyadh',
          },
        },
        // 9 - 18 except fri/sat
        hours: new Array(7).join('.').split('.').map(
          (_, i) => (i > 4 ? [-1] : [9, 17])
        ),
      },
      subscriptions: {
        ...TypesHelpers.createValues(
          'SUBSCRIPTION',
          [
            // ACTIVE
            ...[
              ['1_YEAR', '1-year', 'per year', 'Yearly', [3600]],
              ['3_MONTHS', '3-month', 'quarterly', 'Quarterly', [950]],
              ['1_MONTH', '1-month', 'per month', 'Monthly', [340]],
            ].reduce(
              (
                agr, [
                  duration,
                  durationName,
                  durationLabel,
                  durationPlanLabel,
                  pricePerPlan,
                ], durationIndex,
              ) => {
                [
                  ['Standard', 0, 'standard'],
                ].forEach(([label, percentage, name], planIndex) => {
                  const price = pricePerPlan[planIndex];
                  agr.push({
                    id: `TIER_${
                      200
                      + (durationIndex * 10)
                      + (planIndex) * 1
                    }`,
                    label: `${
                      label
                    } ${
                      durationPlanLabel
                    } / SAR ${
                      TypesHelpers.decimalize(price)
                    } + VAT ${
                      durationLabel
                    } / ${
                      percentage
                    }%`,
                    plan: label,
                    duration,
                    durationLabel,
                    price,
                    percentage,
                    name: `${name}_${durationName}`,
                  });
                });
                return agr;
              },
              [],
            ),
          ],
        ),
      },
      subscriptionsReseller: {
        ...TypesHelpers.createValues('SUBSCRIPTION', [{
          id: 'TIER_10',
          label: '50 SAR year + 10%',
          plan: 'Reseller',
          duration: '1_YEAR',
          monthly: 50,
          price: 50,
          percentage: 10,
        }]),
      },
    }],
  ].map(([id, currency, timezone, config = {}]) => {
    const result = {
      id,
      country: id,
      label: TypesInternationalization.COUNTRIES_MAP[id].name,
      currency,
      timezone,
      ...config,
    };
    result.subscriptionsNameIdMap = result.subscriptions.SUBSCRIPTION_ITEMS
    .reduce(
      (agr, subscription) => {
        if (!subscription.legacy) {
          agr[subscription.name] = subscription.id;
        }
        return agr;
      },
      {},
    );
    result.getCheckoutComVersionBasedOnStoreId = storeId => (
      [
        // '5e88318b8131610026c36284', // The Example Shop
      ].includes(`${storeId}`)
      ? 'v2'
      : result.checkoutComVersion || 'v2'
    );
    result.getCheckoutComTransactionUrlBasedOnStoreId = (
      storeId,
      data,
      forceVersion,
    ) => {
      const version = (
        forceVersion
        || result.getCheckoutComVersionBasedOnStoreId(storeId)
      );
      return `${
        process.env[`REACT_APP_CHECKOUT_${version.toUpperCase()}_URL`]
        || process.env[`CHECKOUT_${version.toUpperCase()}_URL`]
      }/payments${
          version === 'v1'
        ? `?${QueryString.stringify(data[version].query)}`
        : version === 'v2'
        ? `/all-payments${
              data[version].checkoutId
            ? `/payment/${data[version].checkoutId}`
            : data[version].query
            ? `?${QueryString.stringify(data.v2.query)}`
            : ''
          }`
        : ''
      }`;
    };
    result.getCheckoutComApplePaySupportBasedOnStoreId = _storeId => (
      // result.getCheckoutComVersionBasedOnStoreId(storeId) === 'v1'
      true
    );
    return result;
  })),
  {
    getSystemCountry: systemCountry => (
      Types.SYSTEM_COUNTRY_MAP[systemCountry]
    ),
  },
);

export default Types;
