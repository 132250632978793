import React, { useMemo, useState } from 'react';
import { createNamedStyled } from '../../stitches.config';

import Base from './Base';

import { useApiRequest } from '../../context/Api';
import { useStore } from '../../context/Store';
import { useDictionary } from '../../context/Language';
import { parseProduct } from '../../context/Product';

import Carousel from '../../components/Patterns/Carousel';
import Card from '../../components/Elements/Card';
import { Link } from '../../components/Elements/Text';

import Types from '../../modules/types';

const styled = createNamedStyled('Products');

const Node = styled(Base, {
  width: '100%',
});

const Wrapper = styled.named('Wrapper')('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  maxWidth: '$siteWidth',
  margin: '0 auto',
  gap: '$m',
});

const ChoicesList = styled.named('ChoicesList')('div', {
  display: 'flex',
  gap: '$m',
});

const Grid = styled.named('Grid')('div', {
  width: '100%',
  display: 'grid',
});

function useProducts(node, choice) {
  const { data: store } = useStore();
  const { currency: dictionaryCurrency } = useDictionary();
  const { currency: systemCurrency } = Types.getSystemCountry(
    store.systemCountry,
  );
  const currency = dictionaryCurrency[systemCurrency];
  const [{ data, loading }] = useApiRequest({
    url: `products?query=${JSON.stringify(
      choice.mode === 'query'
      ? {
          where: {
            AND: [
              {
                store: store._id,
                'variations.priceBeforeSale': (
                  choice.onSale
                  ? { GT: 0 }
                  : undefined
                ),
                featured: choice.featured ? { EQ: true } : undefined,
              },
              choice.categories?.length
              ? { category: { IN: choice.categories } }
              : null,
              choice.subcategories?.length && choice.categories?.length === 1
              ? { subcategory: { IN: choice.subcategories } }
              : null,
              choice.collections?.length
              ? { collections: { IN: choice.collections } }
              : null,
            ].filter(condition => condition),
          },
          sort: (
            choice?.sort === 'category'
            ? {
                categorySortWeight: -1,
                featured: -1,
                name: 1,
              }
            : choice?.sort === 'price-asc'
            ? {
                featured: -1,
                'variations.0.price': 1,
                name: 1,
              }
            : choice?.sort === 'price-desc'
            ? {
                featured: -1,
                'variations.0.price': -1,
                name: 1,
              }
            : { // date
                featured: -1,
                createdAt: -1,
                name: 1,
              }
          ),
          populate: (
            store.type === 'REFERRAL'
            ? {
                referralStore: true,
                referralProduct: true,
              }
            : undefined
          ),
          limit: (
              Number.isFinite(node.products_limit) && node.products_limit > 0
            ? node.products_limit
            : 12
          ),
        }
      : {
          where: {
            _id: { IN: choice.products.map(item => item.product) },
            store: store._id,
          },
        }
    )}`,
  });
  const results = useMemo(
    () => {
      if (data) {
        if (choice.mode === 'manual') {
          const map = data.reduce(
            (agr, product) => {
              agr[product._id] = parseProduct(
                product,
                store,
                currency,
                false,
              );
              return agr;
            },
            {},
          );
          return choice.products.reduce(
            (agr, { product }) => {
              if (map[product]) {
                agr.push(map[product]);
              }
              return agr;
            },
            [],
          );
        }
        if (choice.mode === 'query') {
          return data.map(product => parseProduct(
            product,
            store,
            currency,
            false,
          ));
        }
      }
      return data || [];
    },
    [data, choice.mode, choice.products, store, currency],
  );

  return [results, loading];
}

function ProductsList({ node, choice, id }) {
  const [serverProducts, loading] = useProducts(node, choice);
  let products = serverProducts;

  if (loading) {
    products = Array.from({ length: 5 }, (_, i) => ({ _id: i, loading: true }));
  }

  if (!loading && !products.length) {
    return null;
  }

  return (node.products_mode === 'grid'
    ? (
      <Grid
        id={id}
        key={`${choice._id}-${node._id}`}
        css={{
          gridTemplateColumns: `repeat(auto-fill, minmax(${
            node?.products_minItemsWidth || '240px'
          }, 1fr))`,
          gridGap: node?.products_gap || '$xs $s',
        }}
      >
        {products.map(product => (
          <Card key={product._id} product={product} loading={product.loading} />
        ))}
      </Grid>
    )
    : (
      <Carousel
        css={{
          padding: 0,
          '& > *': {
            gridAutoColumns: node?.products_minItemsWidth || '240px',
            gridGap: node?.products_gap || '$s',
          },
        }}
        key={`${choice._id}-${node._id}`}
        id={id}
      >
        {products.map(product => (
          <Card key={product._id} product={product} loading={product.loading} />
        ))}
      </Carousel>
    )
  );
}

function ProductsWrapper({ node, id }) {
  const [choice, setChoice] = useState(node?.products_choices[0]);
  return (
    <Wrapper>
      {node.products_choices.length > 1 ? (
        <ChoicesList
          css={{
            justifyContent: node.products_mode === 'grid'
              ? 'center'
              : 'flex-start',
          }}
        >
          {node.products_choices.map(
            (loopChoice) => (
              <Link
                key={loopChoice._id}
                onClick={() => {
                  setChoice(loopChoice);
                }}
                css={{
                  ...choice._id === loopChoice._id && {
                    color: '$brandColor',
                  },
                }}
                data-active={choice._id === loopChoice._id}
              >
                {loopChoice.label}
              </Link>
            )
          )}
        </ChoicesList>
      ) : null}
      <ProductsList
        id={id}
        node={node}
        choice={choice}
      />
    </Wrapper>
  );
  // return <Debug section={section} />;
}

const Products = ({ node, css, renderNode, ...props }) => (
  <Node
    {...props}
    node={node}
    css={{
      cursor: 'pointer',
      ...css,
    }}
    id={node._id}
    className={node.className}
  >
    <ProductsWrapper node={node} />
  </Node>
);

export default Products;

// export default () => null;
